import React, { Component } from 'react';
import './consumer-panel.scss';
import ExternalLink from './external-link';

export default class ConsumerPanel extends Component {

  render() {
    return (
        <div className="consumer-panel container py-2">
            <p>VABYSMO® (faricimab) 120 mg/mL solution for injection for intravitreal use is a <b>Prescription
Medicine</b> for the treatment of neovascular (wet) age-related macular degeneration (nAMD) and
diabetic macular oedema (DMO).</p>
            <p><span className="para-heading">VABYSMO has risks and benefits. Possible common side effects include:</span> eye pain, burning,
stinging, itching, redness, gritty or scratchy feeling; blurred, cloudy or decreased sharpness of vision
or yellowing of colours; increased production of tears or watering eyes or eye discharge; a sensation
that something is in your eye; moving spots (floaters) or shadows in your vision; small or distorted
pupil.</p>
            <p><span className="para-heading">Do not use VABYSMO if:</span> you are allergic to faricimab. Always check the ingredients to make sure
you can use this medicine; if you have or suspect you have an infection in or around your eye; if you
have inflammation in or around your eye (may be indicated by pain and/or redness).</p>
            <p><span className="para-heading">Tell your doctor if you:</span> experience any problems during the treatment.</p>

            <p>You may not see as well after you are given VABYSMO and after the associated eye examinations.
This is temporary. Do not drive or use machines until your eyesight has returned to normal.</p>
            <p>VABYSMO should not be used during pregnancy unless the potential benefit outweighs the potential
risk to your unborn child.</p>
            <p><span className="para-heading">Call your doctor straight away or go to your nearest Accident and Emergency Centre if you notice
any of the following:</span> sudden vision loss or change in vision; signs of a possible eye infection or
inflammation, such as worsening redness of the eye or blood in and around the eye, eye pain,
increased eye discomfort, blurred or decreased vision, an increased number of floaters (small
particles) in your vision, increased sensitivity to light; you experience signs of a stroke, such as
weakness or paralysis of limbs or face or difficulty speaking.</p>

            <p>Use only as directed. If symptoms continue or you have side effects, see your healthcare
professional.</p>
            <p>For more information about VABYSMO talk to your health professional; or visit <ExternalLink
               href="https://www.medsafe.govt.nz/Consumers/CMI/v/vabysmo.pdf">medsafe.govt.nz</ExternalLink> for
               VABYSMO Consumer Medicine Information; or visit <a className="internalLink"
               href="https://www.roche.co.nz/solutions/pharma-solutions" title="roche.co.nz" target="_blank" rel="noreferrer">roche.co.nz</a> or
               call Roche on 0800 276 243.</p>
            <p><span className="para-heading">Ask your doctor if VABYSMO is right for you.</span></p>
            <div className="panel-high-light">
                <p>VABYSMO is an unfunded medicine for nAMD and DMO. Ask your health professional about the cost of the medicine and other fees that may apply.</p>
            </div>
            
            <p className='small-text'>Roche Products (New Zealand) Limited, Auckland. Phone : 0800 276 243 <a className="internalLink" href="https://www.roche.co.nz/solutions/pharma-solutions" target="_blank" rel="noreferrer" title="roche.co.nz">roche.co.nz</a>  All trademarks mentioned herein are protected by law.</p>
              
        </div>
    );
  }
}
